.container {
    display: flex;
    justify-content: center;
    width: 60vw;
}

@media(max-width: 770px) {
    .container {
        width: 100vw;
    }
}